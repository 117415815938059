

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Leaderboard {
  background: #111111;
  padding-bottom: 50vh;
}

.Title {
  background: #111111;
  font-size: x-large;
  text-align: center;
  color: white;
  padding-top: 5%;
}

button{
  background-color: #1F1F1F;
  color: white;
  font-size: 15px;
  padding: 8px 30px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgb(0, 0, 0);
}

header{

  text-align: center;
  /*background: linear-gradient(45deg, #edc99f,#02efee,#0400f8,#01017c,#0001cb);*/
  background-color: #111111;
  color: rgb(0, 0, 0);
  vertical-align: center;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: normal;
  font-size: 100px;
}

.tagline{
  display: flex; 
  height: 50px; 
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #111111; 
  font-size: x-large; 
  font-weight: bolder;
  color: #D5D5D5;
  padding-bottom: 2%;
}

.lb-footer{
  justify-content: right;
  text-align: center;
  background-color: #111111; 
  font-size: large; 
  font-weight: bolder;
  color: #D5D5D5;
  padding-right: 1%;
  padding-bottom: 1%
}

.game-buttons-parent {
  display: flex;
  justify-content: center;
  font-size: large;
  background-color: #111111;
  padding-top: 2%;
  padding-bottom: 2px;
}

.game-buttons-child {
 flex:1;
 padding-left: 3%;
 padding-right: 3%;
}

.category-buttons-parent {
  display: flex;
  justify-content: center;
  font-size: large;
  background-color: #111111;
  padding-left: 2%;
  padding-right: 2%;
}

.category-buttons-child {
 flex:1;
}


@media screen and (max-width: 1200px) and (min-height:800px) {
  header{
    font-size: medium;
  }
  .tagline{
    font-size: medium;
  }
  .game-buttons-parent {
    font-size: smaller;
    padding-top: 2%;
    padding-bottom: 2px;
  }
  .category-buttons-parent {
    font-size: smaller;
    padding-left: 2%;
    padding-right: 2%;
  }
   button{
    font-size: x-small;
    padding: 2px 8px;
    border-radius: 5px;
  }
  .lb-footer{
    font-size: small;
  }
}

@media screen and (max-width: 600px) {
  header{
    font-size: small;
  }
  .tagline{
    font-size: xx-small;
  }
  .game-buttons-parent {
    font-size: xx-small;
    padding-top: 2%;
    padding-bottom: 2px;
  }
  .category-buttons-parent {
    font-size: xx-small;
    padding-left: 0%;
    padding-right: 0%;
  }

   button{
    font-size: xx-small;
    padding: 1px 1px;
    border-radius: 2px;
  }
  .lb-footer{
    font-size: x-small;
  }
}

@media screen and (max-height:800px) and (min-width: 600px) {
  header{
    font-size: small;
  }
  .tagline{
    font-size: x-small;
  }
  .game-buttons-parent {
    font-size: smaller;
    padding-top: 2%;
    padding-bottom: 2px;
  }
  .category-buttons-parent {
    font-size: smaller;
    padding-left: 2%;
    padding-right: 2%;
  }

   button{
    font-size: x-small ;
    padding: 2px 2px;
    border-radius: 5px;
  }
  .lb-footer{
    font-size: xx-small;
  }
}
