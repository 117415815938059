.LeaderBoardPage {
    display: flex;
    justify-content: center; 
    align-items: stretch; 
    padding: 4%;
    font-size: larger; 
    color: #D5D5D5;
}

.LeaderBoard-List{
    display: grid;
    gap: 3px;
    width: 90%; 
    max-width: 1590px;
    align-items: center
}

.LeaderBoardLabel{
    display: flex; 
    height: 50px; 
    align-items: center; 
    justify-content: center; 
    background-color: #D5D5D5; 
    font-size: 25px; 
    color: #1F1F1F
}

.Entry{
    display: flex;
    height: 50px; 
    align-items: center;
    justify-content: center; 
    background-color: #1F1F1F
}

.EntryOther{
    display: flex;
    height: 50px; 
    align-items: center;
    justify-content: center; 
    background-color: #181818
}

.EntryRank{
    font-weight: bolder; 
    flex:2; 
    width: 20%; 
    margin-left: 1%;
}

.EntryText{
    font-weight: bolder; 
    flex:3; 
    width: 20%;
}


.EntryUser{
    font-weight: bolder; 
    flex:3; 
    width: 20%;
}

.EntryVod{
    font-weight: bolder; 
    flex:2; 
    width: 20%;
}

@media screen and (max-width: 1200px) and (min-height:800px) {
    .LeaderBoardPage {    
        font-size: smaller; 
    }
    
    .LeaderBoardLabel{
        height: 5vh; 
        font-size:  medium; 
    }
    
    .Entry{
        height: 5vh; 
    }
}
  
@media screen and (max-width: 600px) and (max-height: 1000px)  {
    .LeaderBoardPage {    
        font-size: x-small; 
    }
    
    .LeaderBoardLabel{
        height: 5vh; 
        font-size: small; 
    }
    
    .Entry{
        height: 5vh; 
    }
}
  
@media screen and (max-height:800px) and (min-width: 600px) {
    .LeaderBoardPage {    
        font-size: smaller; 
    }
    
    .LeaderBoard-List{
        gap: 3px;
        width: 90%; 
    }
    
    .LeaderBoardLabel{
        height: 50px; 
        font-size: small; 
    }
    
    .Entry{
        height: 50px; 
    }
}